<template>
  <div class="mt-5">
    <br />
    <br />
    <br />
    <a
      href="https://analytics.google.com/analytics/web/?authuser=4#/p420521457/reports/reportinghub"
      target="_blank"
      class="btn bg-white"
    >
      <img src="../assets/analytics.png" height="40" alt="" /> Google Analytics
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
